import React from 'react';

import Navigation from '../components/Navigation';
import ProjectList from '../components/Project/ProjectList';
import { ProjectListContextProvider } from '../contexts/projectListContext';
import { IClientUser } from '../interfaces/user';

import feedbackImg from '../assets/svg/feedback.svg';
// import { accessTokenStorage } from '../services/auth';

const HomePage: React.FC<IClientUser> = (props: IClientUser) => {
  return (
    <main className="page-project-list">
      <Navigation {...props} />
      <section className="container">
        <div className="columns section">
          <div className="column">
            <div className="content">
              <h1>Welcome to the community!</h1>
              <p>
                A place to stay up to date about everything that’s going on with
                your projects and connections.
              </p>

              {/* {props?.user?.verified &&
                props?.user?.stripeAccountStatus !== 'complete' && (
                  <>
                    <br />
                    <a href="/stripe-onboard">
                      {props?.user?.stripeAccountStatus === 'incomplete'
                        ? 'Continue Onboarding'
                        : 'Onboard with Stripe'}
                    </a>
                  </>
                )}

              {props?.user?.stripeAccountStatus === 'complete' && (
                <>
                  <br />
                  <a
                    href=""
                    onClick={async (e: any) => {
                      e.preventDefault();

                      const res = await fetch(
                        `${process.env.REACT_APP_API_BASE_URL}/api/v1/stripe/login-link?accountId=${props.user?.stripeId}`,
                        {
                          method: 'GET',
                          headers: {
                            Authorization: `Bearer ${accessTokenStorage.get()}`
                          }
                        }
                      );

                      const data = await res.json();

                      if (data.error) {
                        window.alert(data.message);
                        return;
                      }

                      window.open(data.url, '_blank');
                    }}
                  >
                    Stripe Login
                  </a>
                </>
              )} */}
            </div>
          </div>
          <div className="column is-half">
            <ProjectListContextProvider>
              <ProjectList
                user={props.user as any}
                currentUserId={props.user?.id}
              />
            </ProjectListContextProvider>
          </div>
          <div className="column page-project-list-sidebar">
            <div className="card page-project-list-sidebar-card is-dropshadowed is-rounded">
              <header className="card-header is-bordered-bottom p-t-2 m-b-0">
                <div className="card-header-title is-table has-text-centered">
                  <img src={feedbackImg} alt="feedback" />
                  <p className="is-size-5 m-b-1 has-text-weight-bold">
                    Feedback
                  </p>
                  <p className="content is-small is-light">
                    Would love to hear your feedback so that we can make
                    MatchHat awesome!
                  </p>
                  <button
                    className="button has-background-grey is-borderless is-bordered-radius"
                    onClick={() => {
                      window.open(
                        'https://docs.google.com/forms/d/e/1FAIpQLSe--GSi8vWGLyeSXUeGmmVJG_vXKKWxemUp7FsXcNtPKXwopw/viewform'
                      );
                    }}
                  >
                    <span className="is-small has-text-primary has-text-weight-semibold">
                      Share Feedback
                    </span>
                  </button>
                </div>
              </header>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default HomePage;
