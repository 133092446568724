import React from 'react';
// import { useParams } from 'react-router-dom';

export default function StripeCheckoutCancelPage() {
  // const { session_id } = useParams();

  return (
    <div className="container" style={{ textAlign: 'center' }}>
      <h1 style={{ marginTop: '3rem', fontSize: '2rem' }}>
        Payment has been cancelled!
      </h1>
      <a href="/">Home</a>
    </div>
  );
}
