import type { ApolloQueryResult } from '@apollo/client';

import { GET_CURRENT_USER } from '../graphql/GET_CURRENT_USER';
import { apolloClient } from './apollo';
import { accessTokenStorage } from './auth';

const authenticationUrl = process.env.REACT_APP_API_URL;

const Api = {
  async current(): Promise<any> {
    const response: ApolloQueryResult<any> = await apolloClient.query({
      query: GET_CURRENT_USER
    });

    return response.data;
  },
  logout() {
    accessTokenStorage.clear();
    window.location.replace('/sign-in');
  },
  loginByEmail: async ({
    email,
    password
  }: {
    email: string;
    password: string;
  }): Promise<any> => {
    const response = await fetch(authenticationUrl!, {
      body: `username=${encodeURIComponent(
        email
      )}&password=${encodeURIComponent(password)}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      method: 'POST'
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(body.message || 'Authentication failed');
    }

    if ('error' in body) {
      throw new Error(body.message || `Authentication failed: ${body.error}`);
    }

    const { token } = body;
    accessTokenStorage.set(token);
    const currentUserData = await Api.current();
    return currentUserData.me;
  },
  registerOrLoginWithGoogle: async ({
    googleToken,
    name,
    registerUser = false
  }: {
    googleToken: string;
    name: string;
    registerUser?: boolean;
  }): Promise<any> => {
    const authUrl = registerUser
      ? `${process.env.REACT_APP_API_BASE_URL}/auth/google/sign-up`
      : `${process.env.REACT_APP_API_BASE_URL}/auth/google/sign-in`;
    const response = await fetch(authUrl, {
      body: JSON.stringify({
        name,
        token: googleToken
      }),
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST'
    });

    if (response.ok) {
      const body: any = await response.json();

      if ('error' in body) {
        throw new Error(
          `Authentication failed: HTTP ${body.statusCode}: ${body.error}`
        );
      }

      const { token } = body;
      accessTokenStorage.set(token);
      const currentUserData = await Api.current();

      return currentUserData.me;
    } else {
      throw new Error('Authentication failed');
    }
  },
  createAccountLink: async (
    connectedAccountId: string
  ): Promise<null | string> => {
    try {
      const token = accessTokenStorage.get();

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/stripe/account-link`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({
            accountId: connectedAccountId
          })
        }
      );

      const data = await response.json();
      const { url, error } = data;

      if (error) {
        // console.error('Failed creating account link', error);
        return null;
      }

      return url;
    } catch (error) {
      console.error('Error creating account link', error);
      return null;
    }
  },
  createAccount: async (): Promise<null | string> => {
    try {
      const token = accessTokenStorage.get();

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/stripe/account`,
        {
          method: 'POST',
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      const data = await response.json();
      const { accountId, error } = data;

      if (error) {
        // console.error('Failed creating account', error);
        return null;
      }

      return accountId;
    } catch (error) {
      console.error('Error creating account', error);
      return null;
    }
  }
};

export default Api;
