import { gql } from '@apollo/client';

export const GET_USER = gql`
  query GET_USER($userId: Float!) {
    getUser(userId: $userId) {
      id
      email
      avatar {
        fileUrl
      }
      isOnboarded
      userConfirmed
      confirmationToken
      verified
      stripeId
      stripeAccountStatus
      skills {
        id
        name
      }
      professions {
        id
        name
      }
      profile {
        id
        name
        bio
        website
        collaborationStatus
        availableAt
        country
        city
      }
      desiredCollaborationProfession {
        value: id
        label: name
      }
    }
  }
`;
