import React, {
  FunctionComponent,
  SyntheticEvent,
  useContext,
  useState
} from 'react';
import { Link } from 'react-router-dom';
import binocularsPurpleLightIcon from '../../assets/svg/binoculars-purple-light.svg';
import envelopeOpenMessagePrimary from '../../assets/svg/envelope-open-message-primary.svg';
import userIcon from '../../assets/svg/user-icon.svg';
import { MessageContext } from '../../contexts/messageContext';
import { GET_CURRENT_USER_me } from '../../graphql/types/GET_CURRENT_USER';
import { getCompressedImagePath } from '../../utils/getCompressedImagePath';
import CollaborationStatus, {
  ECollaborationStatus
} from './CollaborationStatus';
import UserBio from './UserBio';
import {
  UserData,
  getUserAvatar,
  getUserName,
  getUserBio,
  getUserProfessions,
  getUserCollaborationStatus,
  getUserDesiredCollaboration
} from '../../types/user';

interface IUserCreativeCardProps {
  user: UserData;
  currentUser: GET_CURRENT_USER_me;
}

export const DEFAULT_MEDIA_AVATAR = 'https://www.gravatar.com/avatar?d=mp';
const MAX_BIO_LENGTH = 105;

const UserCreativeCard: FunctionComponent<IUserCreativeCardProps> = ({
  user,
  currentUser
}) => {
  const { actions } = useContext(MessageContext);
  const [isSelecting, setIsSelecting] = useState(false);

  const handleSendMessageOnClick = async (e: SyntheticEvent) => {
    e.preventDefault();
    setIsSelecting(true);
    try {
      await actions.handleSelectOtherUser(user);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSelecting(false);
    }
  };

  const userAvatar = getUserAvatar(user);
  const compressedAvatarImage = getCompressedImagePath(userAvatar, 'thumbnail');
  const isDefaultMediaAvatar = userAvatar === DEFAULT_MEDIA_AVATAR;
  const userName = getUserName(user);
  const userBio = getUserBio(user);
  const professions = getUserProfessions(user);
  const collaborationStatus = getUserCollaborationStatus(user);
  const desiredCollaboration = getUserDesiredCollaboration(user);

  return (
    <div className="column is-one-third-desktop is-half-tablet is-full-mobile is-flex">
      <div className="card">
        <div className="card-content p-t-1 p-r-1 p-b-2 p-l-1">
          <Link to={`/user/${user.id}`} className="is-clearfix">
            <div className="media m-b-1">
              <div className="media-left">
                <figure
                  className={`image avatar is-80x80 is-inline-flex ${
                    isDefaultMediaAvatar && 'default-media-avatar'
                  }`}
                  style={{
                    backgroundImage: `url(${compressedAvatarImage || userIcon})`
                  }}
                />
              </div>
              <div className="media-right m-l-0">
                {collaborationStatus && (
                  <p className="is-light">
                    <CollaborationStatus
                      collaborationStatus={
                        collaborationStatus as ECollaborationStatus
                      }
                    />
                  </p>
                )}
              </div>
            </div>
            <h3 className="title m-b-0 has-text-black is-5">{userName}</h3>
            {professions && professions.length > 0 && (
              <p className="content is-small has-text-grey-dark m-b-1">
                {professions.map((prof, idx) => (
                  <span key={idx}>{(idx ? ', ' : '') + prof.name}</span>
                ))}
              </p>
            )}
          </Link>
          <div className="content">
            {userBio && userBio.length > MAX_BIO_LENGTH ? (
              <>
                <p className="is-light content is-small m-b-1">
                  {userBio.substr(0, MAX_BIO_LENGTH)}
                  ... <UserBio userIdx={Number(user.id)} />
                </p>
              </>
            ) : (
              userBio && (
                <p className="is-light content is-small m-b-1">{userBio}</p>
              )
            )}
            {desiredCollaboration && (
              <div className="collaborate-with has-background-grey m-b-1">
                <p>
                  <img
                    src={binocularsPurpleLightIcon}
                    alt="binoculars purple light icon"
                    className="image is-14x14 is-inline"
                  />
                  <span className="content is-small has-text-weight-medium">
                    Wants to collaborate with{' '}
                  </span>
                </p>
                <p className="content is-small">{desiredCollaboration.label}</p>
              </div>
            )}
          </div>
        </div>
        {user.id.toString() !== currentUser.id && (
          <a className="send-message" onClick={handleSendMessageOnClick}>
            <img
              className="img"
              src={envelopeOpenMessagePrimary}
              alt="envelope open message"
            />
            <span className="has-text-weight-medium is-small">
              {isSelecting ? 'Loading...' : 'Send a message'}
            </span>
          </a>
        )}
      </div>
    </div>
  );
};

export default UserCreativeCard;
