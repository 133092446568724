import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import { GET_CURRENT_USER } from '../graphql/GET_CURRENT_USER';
import { GET_CURRENT_USER_me } from '../graphql/types/GET_CURRENT_USER';
import { renderContents } from './common';

const initData: GET_CURRENT_USER_me = {
  __typename: 'User',
  avatar: {
    __typename: 'Media',
    fileUrl: ''
  },
  confirmationToken: '',
  desiredCollaborationProfession: {
    __typename: 'Profession',
    label: '',
    value: ''
  },
  email: '',
  id: '',
  isOnboarded: false,
  stripeId: null,
  stripeAccountStatus: null,
  verified: false,
  professions: [],
  profile: {
    __typename: 'UserProfile',
    bio: null,
    collaborationStatus: '',
    id: '',
    name: '',
    website: null,
    country: '',
    city: ''
  },
  skills: [],
  userConfirmed: false
};

export const CurrentUserContext = createContext(initData);

interface ICurrentUserContextProviderProps {
  children: ReactNode;
}

export const CurrentUserContextProvider = (
  props: ICurrentUserContextProviderProps
) => {
  const [currentUserData, setCurrentUserData] =
    useState<GET_CURRENT_USER_me>(initData);

  const { loading, error, data } = useQuery(GET_CURRENT_USER);

  useEffect(() => {
    if (data) {
      setCurrentUserData(data.me);
    }
  }, [data]);

  return (
    <CurrentUserContext.Provider value={currentUserData}>
      {renderContents({
        children: props.children,
        data,
        error,
        loading
      })}
    </CurrentUserContext.Provider>
  );
};
