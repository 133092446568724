import { gql } from '@apollo/client';

export const GET_PROJECT = gql`
  query GET_PROJECT($projectId: String!) {
    getProject(projectId: $projectId) {
      __typename
      author {
        id
        email
        stripeAccountStatus
        stripeId
        avatar {
          fileUrl
        }
        profile {
          id
          name
          bio
          website
        }

        professions {
          id
          name
        }
      }

      id
      type
      title
      locations {
        id
        name
      }
      visibility
      description
      fundingStatus
      videoUrl

      medias {
        id
        fileUrl
      }

      isPublic
      projectStatus
      genres {
        id
        name
      }
      progress

      likes {
        id
        author {
          id
          email

          avatar {
            fileUrl
          }

          profile {
            id
            name
          }
        }
      }

      interested {
        id
        author {
          id
          email

          avatar {
            fileUrl
          }

          profile {
            id
            name
          }
        }
      }

      wasLikedByUser

      followers {
        id
        followBy {
          id
          profile {
            name
          }
        }
      }
      wasFollowedByUser

      collaborations {
        id
        additionalRequirements
        compensation
        currency
        requiredSkills {
          id
          name
        }

        requiredProfessions {
          id
          name
        }

        numberOfPeople

        applicants {
          id
        }

        selectedApplicants {
          id
        }

        isFound
      }

      comments {
        id
        version
        contentJson
        createdAt
        author {
          id
          email

          avatar {
            fileUrl
          }

          profile {
            id
            name
          }
        }

        likes {
          id
          author {
            id
            profile {
              id
              name
            }
          }
        }
      }

      updates {
        id
        title
        description
        medias {
          id
          fileUrl
        }
        author {
          id
          email

          avatar {
            fileUrl
          }

          profile {
            id
            name
          }

          professions {
            id
            name
          }
        }

        likes {
          author {
            id

            profile {
              id
              name
            }
          }
        }

        comments {
          id
          version
          contentJson
          createdAt
          author {
            id
            email

            avatar {
              fileUrl
            }

            profile {
              id
              name
            }
          }
          likes {
            id
            author {
              id
              profile {
                id
                name
              }
            }
          }
          wasCommentLikedByUser
        }

        createdAt
      }

      createdAt
      updatedAt
    }
  }
`;
