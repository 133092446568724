import { Formik } from 'formik';
import * as React from 'react';
import GoogleLogin from '@leecheuk/react-google-login';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
// import useReactRouter from 'use-react-router';
import * as Yup from 'yup';

import googleIcon from '../../../assets/images/icons/google-logo.png';
import { IClientUser } from '../../../interfaces/user';
import Api from '../../../services/api';
import FormField from '../../FormField';

const signInValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('This field is required'),
  password: Yup.string().required('This field is required')
});

const signInInitialValues = {
  email: '',
  password: ''
};

const SignInForm: React.FunctionComponent<IClientUser> = () => {
  const history = useHistory();

  const responseGoogle = async response => {
    if ('accessToken' in response) {
      const token = response.accessToken;
      try {
        await Api.registerOrLoginWithGoogle({
          googleToken: token,
          name: response.profileObj.name
        });
        history.replace('/');
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  return (
    <Formik
      initialStatus={{
        auth: {
          error: ''
        }
      }}
      initialValues={signInInitialValues}
      validationSchema={signInValidationSchema}
      onSubmit={async (values, { setSubmitting, setStatus }) => {
        const { email, password } = values;
        try {
          await Api.loginByEmail({
            email,
            password
          });
          history.replace('/');
        } catch (error: any) {
          const errorMessage = error.message.replace('Error: ', '');
          setStatus({
            auth: {
              error: errorMessage
            }
          });
        }
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, status, isSubmitting }) => {
        return (
          <form onSubmit={handleSubmit} className="page-sign-in-form">
            <div className="form-box">
              <header className="content">
                <h1>Log in to MatchHat</h1>
                <span>Please enter your credentials to proceed.</span>
              </header>
              {status.auth.error && (
                <div className="notification is-danger">
                  {status.auth.error}
                </div>
              )}
              <div className="field">
                <div className="field-body">
                  <FormField name="email" type="text" placeholder="Email" />
                </div>
              </div>
              <div className="field">
                <div className="field-body">
                  <FormField
                    name="password"
                    type="password"
                    placeholder="Password"
                  />
                </div>
              </div>
              <div className="field field-login">
                <button
                  type="submit"
                  className={`button has-text-weight-bold is-primary ${isSubmitting &&
                    'is-loading'}`}
                  disabled={isSubmitting}
                >
                  Login
                </button>
              </div>
              <div className="field has-text-centered">
                <Link to="/reset-password">
                  <span className="has-text-weight-bold is-small">
                    Forgot Password
                  </span>
                </Link>
              </div>
              <div className="divider-box">
                <span className="divider-lol">
                  <span className="divider-text">
                    <i>or</i>
                  </span>
                </span>
              </div>
              {process.env.REACT_APP_GOOGLE_CLIENT_ID && (
                <GoogleLogin
                  clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                  buttonText="Login"
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  cookiePolicy={'single_host_origin'}
                  responseType={'id_token'}
                  render={(renderProps: {
                    onClick: () => void;
                    disabled: boolean;
                  }): any => {
                    if (renderProps.disabled) {
                      return null;
                    }

                    return (
                      <div className="">
                        <div className="field field-google-login">
                          <button
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                            type="button"
                            className="button is-primary is-outlined"
                            style={{ borderColor: '#e4e4e4' }}
                          >
                            <img
                              alt="google-login-button"
                              src={googleIcon}
                              className="m-r-1"
                            />
                            Log in with google
                          </button>
                        </div>
                      </div>
                    );
                  }}
                />
              )}
              <div className="field field-register has-text-centered m-t-1 m-b-1">
                <span className="is-small">Don't have an account? </span>
                <Link to="/sign-up">
                  <span className="is-small has-text-weight-bold has-text-primary">
                    Sign up
                  </span>
                </Link>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default SignInForm;
