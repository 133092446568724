import React from 'react';
// import { useParams } from 'react-router-dom';

export default function StripeOnboardReturnPage() {
  // const { connectedAccountId } = useParams();

  return (
    <div className="container section">
      <div className="banner">
        <h2>MatchHat</h2>
      </div>
      <div className="content">
        <h2>Details submitted</h2>
      </div>

      <a href="/">Home</a>
    </div>
  );
}
