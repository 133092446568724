import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Api from '../../services/api';

export default function StripeOnboardRefreshPage() {
  const { connectedAccountId } = useParams();
  const [accountLinkCreatePending, setAccountLinkCreatePending] =
    useState(false);
  const [error, setError] = useState(false);

  React.useEffect(() => {
    (async () => {
      if (connectedAccountId) {
        setAccountLinkCreatePending(true);

        const url = await Api.createAccountLink(connectedAccountId);

        setAccountLinkCreatePending(false);

        if (!url) {
          setError(true);
          return;
        }

        window.location.href = url;
      }
    })();
  }, [connectedAccountId]);

  return (
    <div className="container">
      <div className="banner">
        <h2>MatchHat</h2>
      </div>
      <div className="content">
        <h2>Add information to start accepting money</h2>

        {error && <p className="error">Something went wrong!</p>}
      </div>
      <div className="dev-callout">
        {connectedAccountId && (
          <p>
            Your connected account ID is:{' '}
            <code className="bold">{connectedAccountId}</code>
          </p>
        )}
        {accountLinkCreatePending && <p>Creating a new Account Link...</p>}

        <a href="/">Home</a>
      </div>
    </div>
  );
}
