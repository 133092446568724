import React, { useState } from 'react';
import Api from '../../services/api';
import Navigation from '../../components/Navigation';
import { IClientUser } from '../../interfaces/user';

export default function StripeOnboardPage(props: IClientUser) {
  const [accountCreatePending, setAccountCreatePending] = useState(false);
  const [accountLinkCreatePending, setAccountLinkCreatePending] =
    useState(false);
  const [error, setError] = useState(false);
  const [connectedAccountId, setConnectedAccountId] = useState<string>(
    props.user?.stripeId ?? ''
  );

  return (
    <main>
      <Navigation {...props} />
      <section className="container">
        <div className="section">
          {!connectedAccountId && (
            <p style={{ textAlign: 'center', marginTop: '3rem' }}>
              Join Our Team
            </p>
          )}
          {connectedAccountId && (
            <h1 style={{ fontSize: '2rem' }}>
              Add information to start accepting money
            </h1>
          )}
          {connectedAccountId && (
            <p>MatchHat partners with Stripe to help you receive payments.</p>
          )}
          {!accountCreatePending && !connectedAccountId && (
            <div style={{ textAlign: 'center' }}>
              <br />
              <button
                onClick={async () => {
                  setAccountCreatePending(true);
                  setError(false);

                  const accountId = await Api.createAccount();

                  setAccountCreatePending(false);

                  if (!accountId) {
                    setError(true);
                    return;
                  }

                  setConnectedAccountId(accountId);
                }}
              >
                Create an account!
              </button>
            </div>
          )}
          {connectedAccountId && !accountLinkCreatePending && (
            <button
              onClick={async () => {
                setAccountLinkCreatePending(true);
                setError(false);

                const url = await Api.createAccountLink(connectedAccountId);

                setAccountLinkCreatePending(false);

                if (!url) {
                  setError(true);
                  return;
                }

                window.location.href = url;
              }}
            >
              Add information
            </button>
          )}
          {error && <p className="error">Something went wrong!</p>}
          {(connectedAccountId ||
            accountCreatePending ||
            accountLinkCreatePending) && (
            <div className="dev-callout">
              {connectedAccountId && (
                <p>
                  Your connected account ID is:{' '}
                  <code className="bold">{connectedAccountId}</code>
                </p>
              )}
              {accountCreatePending && <p>Creating a connected account...</p>}
              {accountLinkCreatePending && (
                <p>Creating a new Account Link...</p>
              )}
            </div>
          )}
        </div>
      </section>
    </main>
  );
}
